import { StarIcon } from '@heroicons/react/20/solid'

import Image from 'next/image'
import Link from 'next/link'

import {
  StarIcon as OutlineStar
} from '@heroicons/react/24/outline'

import dashboardImage from '@/images/screenshots/dashboard-2.png'

import EmailForm from '@/components/landing-page/EmailForm'
import { G2Icon } from '../core/icons/G2Icon'

export default function Hero (props) {
  return (
    <div className="bg-white">
      <div className="relative isolate pt-[150px]">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className=" sm:py-20 lg:pb-40">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="hidden sm:mb-8 sm:flex sm:justify-center">
              <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                {' '}
                <a
                  href="/savings-calculator/"
                  className="font-semibold text-indigo-600"
                >
                  <span className="absolute inset-0" aria-hidden="true" />
                  Check out how much you can save{' '}
                  <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div>
            <div className="mx-auto text-center">
              <h1 className="max-w-7xl text-3xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                <span className="block">Affordable</span>
                <span className="text-indigo-600">email</span> &{' '}
                <span className="text-green-500">whatsapp</span>
                <br className="" />
                <span className="">marketing</span>{' '}
                <span className="">automation</span>
              </h1>
              <p className="mx-auto mt-6 max-w-2xl font-medium leading-8 text-gray-600 sm:text-lg">
                <span className="block xl:inline">
                  Send emails and whatsapp messages to your customers with ease
                  and at a fraction of the cost.
                </span>
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                {props.user ? (
                  <Link
                    href="/get-started"
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Go to dashboard <span aria-hidden="true">→</span>
                  </Link>
                ) : (
                  <EmailForm />
                )}
              </div>
              <a
                href="https://www.g2.com/products/campaignhq-campaignhq/reviews"
                className="mx-auto mt-10 flex flex-col items-center space-y-3 sm:w-[400px] sm:flex-row sm:space-x-3 sm:space-y-0"
              >
                <div className="flex">
                  <StarIcon className="mx-auto h-auto w-10  text-[#ff492c]" />
                  <StarIcon className="mx-auto h-auto w-10  text-[#ff492c]" />
                  <StarIcon className="mx-auto h-auto w-10  text-[#ff492c]" />
                  <StarIcon className="mx-auto h-auto w-10  text-[#ff492c] " />
                  <div className="relative overflow-hidden">
                    <OutlineStar className="mx-auto h-auto w-10  text-[#ff492c]" />
                    <div className="absolute  top-0 z-10 w-5 overflow-hidden text-[#ff492c]">
                      <StarIcon className="text-yellow mx-auto h-auto  w-10 " />
                    </div>
                  </div>
                </div>
                <div>Rated 4.6/5 on</div>
                <G2Icon
                  className="mx-auto h-auto w-[45px]  text-[#ff492c]"
                  title="CampaignHQ is rated 4.6 on G2"
                />
              </a>
            </div>
            <div className="mt-16 flow-root sm:mt-24">
              <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                <Image
                  className="rounded-md shadow-2xl ring-1 ring-gray-900/10"
                  src={dashboardImage}
                  alt="Best email marketing tool in India"
                  priority
                  width={2432}
                  height={1442}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
    </div>
  )
}
