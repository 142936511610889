import { Button } from '@/components/core/Button'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import api from '@/api'
import Select from '@/components/core/Select'
import TextArea from '@/components/core/TextArea'
import TextField from '@/components/core/TextField'
import volopayUser from '@/images/testimonials/volopaycto.jpeg'
import VolopayLogo from '@/images/testimonials/volopaylogo.png'
import { CatchPromise } from '@/utils/catch-promise'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
export default function ConfirmArchiveModal ({ open = false, setOpen, listId }) {
  const dispatch = useDispatch()
  const router = useRouter()
  const cancelButtonRef = useRef(null)

  const closeModal = () => {
    setOpen(false)
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isSubmitSuccessful, isDirty, isValid },
  } = useForm()


  const planOptions = [
    {
      value: 'unlimited_subscribers',
      name: 'Unlimited Subscribers',
    },
    {
      value: '1_time_payment',
      name: '1 time payment',
    },
    {
      value: 'custom',
      name: 'Custom',
    }
  ]

  const handlePlanChange = (e) => {
    setValue('plan', e.value)
    setSelectedPlanOption(e)
  }

  const [selectedPlanOption, setSelectedPlanOption] = useState(planOptions[0])


  useEffect(() => {
    setValue('plan', selectedPlanOption.value)
  }, [])

  const save = async (data) => {
    const [err, res] = await CatchPromise(
      api.subscribers.contactSales({ ...data, custom_fields: { company: data.company, message: data.message, plan: data.plan, source: 'pricingCTA' } })
    )
    if (res) {
      router.push('/contact-us/thank-you')
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8  sm:p-6">
                <div>
                  <div className="px-4 py-5 sm:p-6">
                    <Dialog.Title
                      as="h3"
                      className="text-center text-[30px] font-semibold leading-6 text-gray-900"
                    >
                      We offer the best pricing in the market <br />{' '} <div className='mt-4'>if not we will match it!</div>
                    </Dialog.Title>

                    <div className='flex flex-col gap-16 sm:gap-y-20 sm:flex-row  mx-auto'>
                      <form onSubmit={handleSubmit(save)} className="mx-auto mt-16 sm:mt-20 lg:min-w-[600px]">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                          <div>
                            <TextField
                              label="First name"
                              name="first_name"
                              register={register}
                              validations={{
                                required: 'First name is required',
                              }}
                              error={errors.first_name}
                            />
                          </div>
                          <div>
                            <TextField
                              label="Last name"
                              name="last_name"
                              register={register}
                            />
                          </div>
                          <div className="sm:col-span-2">
                            <TextField
                              label="Company"
                              name="company"
                              register={register}
                            />
                          </div>
                          <div className="sm:col-span-2">
                            <TextField
                              label="Email"
                              name="email"
                              register={register}
                              validations={{
                                required: 'Email is required',
                                pattern: {
                                  value: /\S+@\S+\.\S+/,
                                  message: 'Entered value does not match email format',
                                },

                              }}
                              error={errors.email}
                            />
                          </div>
                          <div className="sm:col-span-2">
                            <Select
                              label="What plan are you interested in?"
                              name="plan"
                              register={register}
                              options={planOptions}
                              value={selectedPlanOption}
                              onSelect={handlePlanChange}
                            />
                          </div>
                          <div className="sm:col-span-2">
                            <TextArea
                              label="Message"
                              name="message"
                              placeholder="Tell us about how many emails you send per month, your current email service provider, and any other details you'd like to share."
                              register={register}
                              validations={{
                                required: 'Message is required',
                              }}
                              error={errors.message}
                            />
                          </div>
                          {/* <Switch.Group as="div" className="flex gap-x-4 sm:col-span-2">
            <div className="flex h-6 items-center">
              <Switch
                checked={agreed}
                onChange={setAgreed}
                className={classNames(
                  agreed ? 'bg-indigo-600' : 'bg-gray-200',
                  'flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                )}
              >
                <span className="sr-only">Agree to policies</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    agreed ? 'translate-x-3.5' : 'translate-x-0',
                    'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out'
                  )}
                />
              </Switch>
            </div>
            <Switch.Label className="text-sm leading-6 text-gray-600">
              By selecting this, you agree to our{' '}
              <a href="#" className="font-semibold text-indigo-600">
                privacy&nbsp;policy
              </a>
              .
            </Switch.Label>
          </Switch.Group> */}
                        </div>
                        <div className="mt-10">
                          <Button laoding={isSubmitting} disabled={isSubmitting || !isValid} type="submit" text="Get in touch" className="mt-4 w-full" />
                        </div>
                      </form>
                      <div className="lg:mt-[200px] lg:w-[600px] lg:flex-none ">
                        <div className="flex flex-col pb-10 sm:pb-16 lg:pb-0 lg:pr-8 xl:pr-20">
                          <Image
                            className="h-7 self-start"
                            src={VolopayLogo}
                            alt="volopay logo"
                            priority
                          />
                          <figure className="mt-10 flex flex-auto flex-col justify-between">
                            <blockquote className="text-lg leading-8 text-gray-900">
                              <p>
                                We are extremely satisfied with CampaignHQ. The improved
                                pricing, user-friendly platform, and efficient campaign
                                creation have made our product email updates efforts much
                                more streamlined and effective. We highly recommend
                                CampaignHQ to anyone seeking a hassle-free and
                                cost-effective solution for their multi-channel marketing.
                              </p>
                            </blockquote>
                            <figcaption className="mt-10 flex items-center gap-x-6">
                              <Image
                                className="h-14 w-14 rounded-full bg-gray-50"
                                src={volopayUser}
                                alt="Rajesh Raikwar"
                                priority
                              />
                              <div className="text-base">
                                <div className="font-semibold text-gray-900">
                                  Rajesh Raikwar
                                </div>
                                <div className="mt-1 text-gray-500">
                                  CTO of Volopay (YC company)
                                </div>
                              </div>
                            </figcaption>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
