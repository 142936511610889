import { useState } from 'react'
import { useRouter } from 'next/router'
import api from '@/api'
import { CatchPromise } from '@/utils/catch-promise'
import { CalendarDaysIcon } from '@heroicons/react/24/outline'
export default ({ SignupButton }) => {
  const [email, setEmail] = useState('')
  const [isEmailValid, setEmailValid] = useState(false)
  const [addingSubscriber, setAddingSubscriber] = useState(false)
  const router = useRouter()
  const onEmailFieldChange = (e) => {
    setEmail(e.target.value)
    const isEmailValid = e.target.value.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    )
    setEmailValid(isEmailValid)
  }
  const addSubscriber = async () => {
    if (addingSubscriber) {
      return
    }
    if (email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      setAddingSubscriber(true)
      const [err, res] = await CatchPromise(
        api.subscribers.add({
          email,
        })
      )
      setAddingSubscriber(false)
    }

    router.push({ pathname: '/signup', query: { email } })
  }

  return (
    <div className='grow sm:grow-0'>
      <div className="mx-auto mt-2 mb-7 flex w-full justify-center text-sm text-gray-500">
        <a
          href="https://calendly.com/campaignhq"
          className="flex w-full items-center justify-center rounded-md bg-white px-3.5 py-2.5 text-lg font-semibold text-indigo-900 shadow-sm ring-2 ring-inset ring-indigo-600 hover:bg-gray-50"
        >
          <CalendarDaysIcon className="mr-2 inline-block h-5 w-5" />
          <span>Book a demo</span>
        </a>
      </div>
      <div className="flex flex-col space-y-3 md:flex-row md:space-y-0 md:space-x-3">
        <input
          type="text"
          onChange={(e) => onEmailFieldChange(e)}
          placeholder="Email"
          className="w-full rounded-md border-gray-300 bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-600 md:min-w-[300px]"
        />

        {SignupButton ? (
          <SignupButton onClick={(e) => addSubscriber(e)} />
        ) : (
          <button
            onClick={(e) => addSubscriber(e)}
            className="cursor-pointer rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:w-full"
          >
            Try for free {'->'}
          </button>
        )}
      </div>
    </div>
  )
}
