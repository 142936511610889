import Footer from '@/components/landing-page/Footer'
import Header from '@/components/landing-page/Header'
import Hero from '@/components/landing-page/Hero.jsx'
import Integrations from '@/components/landing-page/Integrations'
import { NextSeo } from 'next-seo'
import Head from 'next/head'
// import Zapier from '@/components/landing-page/Zapier';
import Channels from '@/components/landing-page/Channels'
import MainFeatures from '@/components/landing-page/Features'
import FeatureScroll from '@/components/landing-page/FeatureScroll'
import Pricing from '@/components/landing-page/Pricing'
import SignupCTAForm from '@/components/landing-page/SignupCTAForm'
import Testimonials from '@/components/landing-page/Testimonials'
import { useSession } from '@/hooks/useSession'
import { setCurrentUser } from '@/store/sessionSlice'
import { BreadcrumbJsonLd, OrganizationJsonLd } from 'next-seo'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import HoldOnCta from './landing-page/HoldOnCta'
export default function Home () {
  const dispatch = useDispatch()
  const { validate } = useSession()
  const currentUser = useSelector((state) => state.sessions.currentUser)
  const [openTalkToUsModal, setOpenTalkToUsModal] = useState(true)
  useEffect(() => {
    ; (async () => {
      await validate((user) => dispatch(setCurrentUser(user)))
    })()
    return () => { }
  }, [])

  return (
    <>

      <NextSeo
        title="CampaignHQ - Email & WhatsApp Marketing Automation Software"
        description="CampaignHQ is a marketing automation platform offering email marketing software, bulk WhatsApp messaging, personalization, and analytics to boost growth."
        openGraph={{
          url: 'https://campaignhq.co/',
          title: 'CampaignHQ - Email & WhatsApp Marketing Automation Software',
          description: 'CampaignHQ is a marketing automation platform offering email marketing software, bulk WhatsApp messaging, personalization, and analytics to boost growth.',
          images: [
            {
              url: 'https://campaignhq-prod-public.s3.ap-southeast-1.amazonaws.com/ss-chq.png',
              width: 1200,
              height: 630,
              alt: 'CampaignHQ',
            },
          ],
          site_name: 'CampaignHQ',
          locale: 'en_US',
          type: 'article',
        }}
        twitter={{
          handle: '@campaignhqco',
          site: '@campaignhqco',
          cardType: 'summary_large_image',
        }}

        additionalMetaTags={
          [
            {
              property: 'twitter:image',
              content: 'https://campaignhq-prod-public.s3.ap-southeast-1.amazonaws.com/ss-chq.png',
            },
            {
              property: 'twitter:image:alt',
              content: 'CampaignHQ - Email & WhatsApp Marketing Automation Software',
            }
          ]
        }
      />

      <Head>
        <title>
          Email & WhatsApp Marketing Automation - CampaignHQ
        </title>
        <meta
          name="description"
          content="CampaignHQ is a multi channel marketing automation platform that helps you to send emails, WhatsApp messages."
        />
      </Head>
      <BreadcrumbJsonLd
        itemListElements={[
          {
            position: 1,
            name: 'CampaignHQ',
            item: 'https://campaignhq.co/',
          }
        ]}
      />
      <OrganizationJsonLd
        url="https://campaignhq.co/"
        logo="https://campaignhq-prod-public.s3.ap-southeast-1.amazonaws.com/ss-chq.png"
        name="CampaignHQ"
        description="CampaignHQ is a multi channel marketing automation platform that helps you to send emails, WhatsApp messages."
        sameAs={[
          'https://www.facebook.com/campaignhq.co',
          'https://twitter.com/campaignhqco',
          'https://www.linkedin.com/company/campaignhq',
        ]}
        address={{
          streetAddress: '1404 T-6,Salarpuria',
          addressLocality: 'Bangalore',
          addressRegion: 'Karnataka',
          postalCode: '560103',
          addressCountry: 'India',
        }}
      />

      <main>
        <Header user={currentUser} withNav={true} />
        <Hero user={currentUser} />
        <Integrations />
        <MainFeatures />
        <FeatureScroll />
        {/* <EmailAndWA/> */}
        {/* <AllInOnePlatform /> */}
        <Channels />
        <SignupCTAForm />
        <Pricing />
        <HoldOnCta />

        {/* <PrimaryFeatures /> */}

        {/* <Features /> */}
        {/* <Pricing2 /> */}

        {/* <Support /> */}
        <Testimonials></Testimonials>

        {/* <ContactSalesCTA /> */}
        {/* <EmailProviders></EmailProviders> */}
        {/* <Zapier></Zapier> */}
        {/* <FAQ /> */}
      </main>
      {/* <TalkToUsModal
        open={openTalkToUsModal}
        setOpen={setOpenTalkToUsModal}
      ></TalkToUsModal> */}
      <Footer />
    </>
  )
}
